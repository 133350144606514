/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/ButtonSpinner';

import { getVariationById } from 'actions/admin/fittingVariation';
import { getCategories } from 'actions/admin/product';
import { ATTRIBUTE_WEIGHT_UNIT } from 'constants/index';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { postFittingAttribute } from 'actions/admin/fittings';

const AddEditFittingAttributeVariation = ({
  toggle,
  isOpen,
  modal_loading,
  errorList,
  setIsNewCreated,
  create,
  isEdit,
  variationId,
  getVariationById,
  currentVariation,
  edit,
  getCategories,
  category_loading,
  categorySubCategoryList,
  attributeId,
  fittingType,
  update,
}) => {
  //########################## use effect to get current attribute ##############################
  const { attribute_id: attribute_id } = useParams();

  useEffect(() => {
    if (variationId != null) getVariationById(variationId);
  }, [variationId]);

  //########################## use dispatch ##############################

  //########################## set the initial form data ##############################

  const initialState = {
    title: '',
    part_no: '',
    price: '',
    inventory: '',
    variation_weight: '',
    thumbnail: '',
    image: '',
    selectedFile: '',
    oldimage: '',
    categoryIds: [],
    selectedCategoryIds: [],
    isNewFileSelected: 0,
    isPartNo: '',
    is_option: true,
    includeAllFields: false,
  };

  const [formData, setFormData] = useState(initialState);
  const [includeAllFields, setIncludeAllFields] = useState(false);

  //###################### get the category and sub category list ########################
  const [categoryList, setCategoryList] = useState([]);

  useMemo(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (Object.keys(categorySubCategoryList).length > 0) {
      let tempCategoryList = [];
      categorySubCategoryList.forEach((catData, index) =>
        tempCategoryList.push({
          value: catData._id,
          label: catData.name,
        })
      );
      setCategoryList(tempCategoryList); // set category list in value and label form
    }
  }, [categorySubCategoryList]);

  //########################## use effect to set form data from current attribute ##############################
  useEffect(() => {
    if (variationId != null && Object.keys(currentVariation).length > 0) {
      setFormData({
        ...formData,
        title: !currentVariation.title ? '' : currentVariation.title,
        part_no: !currentVariation.part_no ? '' : currentVariation.part_no,
        variation_weight: !currentVariation.variation_weight
          ? ''
          : currentVariation.variation_weight,
        price: !currentVariation.price ? 0 : currentVariation.price,
        inventory: !currentVariation.inventory
          ? ''
          : currentVariation.inventory,

        categoryIds: !currentVariation.categoryIds
          ? []
          : currentVariation.categoryIds,
        selectedCategoryIds: !currentVariation.selectedCategoryIds
          ? []
          : currentVariation.selectedCategoryIds,
        oldimage: !currentVariation.image ? '' : currentVariation.image,
        thumbnail: !currentVariation.image
          ? ''
          : //process.env.REACT_APP_AWS_STORAGE_URL +
          'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/fitting-variation/' +
          currentVariation.image,
      });
    }
  }, [currentVariation]);

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //########################## reset for data when modal is closed ##############################
  const resetModal = () => {
    //reset the submit state
    setIsSubmit(false);
    //reset the form data to initial state
    setFormData({ ...initialState, categoryIds: [] });
    //reset multiselect dropdown
    setSelected([]);
  };

  //########################## change event for all inputs ##############################
  const [requiredValue, setRequiredValue] = useState(false);
  const onChange = (e) => {
    if (e.target.name === 'image') {
      setRequiredValue(false);

      let image_file = e.target.files[0];

      let image_url = URL.createObjectURL(image_file);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        thumbnail: image_url,
        selectedFile: image_file,
        isNewFileSelected: 1,
      });
    } else if (e.target.name == 'part_no') {
      if (e.target.value > 0) {
        setRequiredValue(true);
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          isPartNo: 1,
        });
      } else {
        setRequiredValue(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const [selected, setSelected] = useState([]); // multiselect selection state

  //############# use effect to set selected category list on dropdown selection #########
  useEffect(() => {
    let selectedCategories = [];
    selected.forEach((value) => {
      selectedCategories.push(value.value);
    });
    setFormData({
      ...formData,
      categoryIds: selectedCategories,
    });
  }, [selected]);

  useEffect(() => {
    let val;
    if (fittingType?.toLowerCase().includes('grip')) {
      val = true;
    } else {
      val = false;
    }
    setIncludeAllFields(val);
  }, [fittingType]);
  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (attribute_id == null) {
      create({ ...formData, includeAllFields }, attributeId).then((res) => {
        if (res === true) {
          setIsNewCreated(true);
          toggle();
          update();
        } else {
          setIsSubmit(false);
        }
      });
    } else {
      edit(
        { ...formData, includeAllFields },
        attributeId,
        variationId,
        fittingType
      ).then((res) => {
        if (res === true) {
          setIsNewCreated(true);
          toggle();
        } else {
          setIsSubmit(false);
        }
      });
    }
  };
  const buttonName = fittingType === 'grips' ? 'Grip Brand' : 'Adaptor Setting';

  return (
    (!category_loading || categoryList !== null) && (
      <div className="animated fadeIn">
        <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal}>
          <ModalHeader toggle={toggle}>
            {isEdit ? 'Edit' : 'Add'} {buttonName}
          </ModalHeader>

          <Form className="form-horizontal">
            <ModalBody>
              {includeAllFields && (
                <>
                  <FormGroup>
                    <Label htmlFor="title">
                      Variation Fitting Attribute Title <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="title"
                      name="title"
                      maxLength={300}
                      value={formData.title}
                      placeholder="Enter variation fitting attribute title"
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.title ? true : false}
                    />
                    <Errors current_key="title" key="title" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="part_no">
                      Part No. <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="part_no"
                      name="part_no"
                      maxLength="100"
                      placeholder="Enter part number"
                      value={formData.part_no}
                      onChange={(e) => onChange(e)}
                      invalid={errorList.part_no ? true : false}
                    />
                    <Errors current_key="part_no" key="part_no" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="image"> Image</Label>
                    <Input
                      type="file"
                      id="image"
                      name="image"
                      onChange={(e) => onChange(e)}
                      invalid={errorList.image ? true : false}
                    />
                    <img
                      src={formData.thumbnail}
                      className="preview-img"
                      alt=""
                    />
                    <Input
                      type="hidden"
                      id="oldimage"
                      name="oldimage"
                      value={formData.oldimage}
                    ></Input>
                    <Errors current_key="image" key="image" />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="variation_weight">
                      Weight {ATTRIBUTE_WEIGHT_UNIT} <span>*</span>
                    </Label>
                    <Input
                      type="number"
                      id="variation_weight"
                      name="variation_weight"
                      min={0}
                      step={0.01}
                      value={formData.variation_weight}
                      placeholder="Enter attribute weight"
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.variation_weight ? true : false}
                    />
                    <Errors
                      current_key="variation_weight"
                      key="variation_weight"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="price">
                      Price <span>*</span>
                    </Label>
                    <Input
                      type="number"
                      id="price"
                      name="price"
                      placeholder="Enter price"
                      min={0}
                      step={0.01}
                      value={formData.price}
                      onChange={(e) => onChange(e)}
                      invalid={errorList.price ? true : false}
                    />
                    <Errors current_key="price" key="price" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="inventory">
                      Inventory <span>*</span>
                    </Label>
                    <Input
                      type="number"
                      id="inventory"
                      name="inventory"
                      placeholder="Enter inventory"
                      min={0}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={formData.inventory}
                      onChange={(e) => onChange(e)}
                      required={requiredValue}
                      invalid={errorList.inventory ? true : false}
                    />
                    <Errors current_key="inventory" key="inventory" />
                  </FormGroup>
                </>
              )}
              {!includeAllFields && (
                <>
                  <FormGroup>
                    <Label htmlFor="title">
                      Attribute Title <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="title"
                      name="title"
                      maxLength={300}
                      value={formData.title}
                      placeholder="Enter attribute title"
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.title ? true : false}
                    />
                    <Errors current_key="title" key="title" />
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter className="modalFooter">
              {modal_loading ? (
                <Spinner />
              ) : (
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                  onClick={(e) => onSubmit(e)}
                  disabled={isSubmit}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
              )}
              <Button type="reset" size="sm" color="danger" onClick={toggle}>
                <i className="fa fa-ban"></i> Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  );
};

AddEditFittingAttributeVariation.defaultProps = {
  isOpen: false,
  setIsNewCreated: false,
  isEdit: false,
  variationId: null,
};

AddEditFittingAttributeVariation.propTypes = {
  errorList: PropTypes.array.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  create: PropTypes.func.isRequired,
  getVariationById: PropTypes.func.isRequired,
  currentVariation: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  category_loading: PropTypes.bool.isRequired,
  categorySubCategoryList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.fittingVariation.modal_loading,
  currentVariation: state.fittingVariation.currentVariation,
  category_loading: state.product.category_loading,
  categorySubCategoryList: state.product.categorySubCategoryList,
});
export default connect(mapStateToProps, {
  create: postFittingAttribute,
  getVariationById,
  edit: postFittingAttribute,
  getCategories,
})(AddEditFittingAttributeVariation);
